import React, { useEffect } from 'react';
import '../public/Styles/Gallery.css';

const images = [
  '/Images/1ebc60bd870926577f187.jpg',
  '/Images/35fad8423bf69aa8c3e710.jpg',
  '/Images/cb31ca3c4e0ce052b91d.jpg',
  '/Images/5fbc66139fa73ef967b64.jpg',
  '/Images/6ca7d5d42d608c3ed5713.jpg',
  '/Images/227c9d256591c4cf9d801.jpg',
  '/Images/477e1e3ff98b58d5019a8.jpg',
  '/Images/824f6c30948435da6c952.jpg',
  '/Images/75075913bea71ff946b66.jpg',
  '/Images/cd60ec2f0a9babc5f28a5.jpg',
  '/Images/e7568235fff52fab76e4.jpg',
  '/Images/f5667b199cad3df364bc9.jpg',
];

const Gallery = () => {
  useEffect(() => {
    let currentIndex = 0;
    const slides = document.getElementsByClassName('slide-image');
    const showSlides = () => {
      for (let i = 0; i < slides.length; i++) {
        slides[i].style.display = 'none';
      }
      currentIndex++;
      if (currentIndex > slides.length) {
        currentIndex = 1;
      }
      slides[currentIndex - 1].style.display = 'block';
      setTimeout(showSlides, 2000);
    };
    showSlides();
  }, []);

  return (
    <div className="gallery">
      <h3 className="gallery-title">My Gallery</h3>
      {images.map((image, index) => (
        <img key={index} src={image} alt={`Slide ${index + 1}`} className="slide-image" />
      ))}
    </div>
  );
}

export default Gallery;
