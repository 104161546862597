import React from 'react';
import '../public/Styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer" id="contact-footer">
      <p>&copy;Copyright: HadesZhang</p>
      <p>Email: truongdangminhtan333@gmail.com</p>
      <p>Facebook: Tân Minh  (Hades)</p>
      <p>Phone(zalo): +84.65917852</p>
    </footer>
  );
};

export default Footer;
