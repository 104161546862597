import React from 'react';
import '../public/Styles/MainContent.css';
const MainContent = () => {
  return (
    <div className="main-content">
      <section>
        <h2>Summary</h2>
        <p className="summary-text"> I am a student majoring in <strong>Software Engineering</strong>. Although I have not graduated yet, I have been participating in many projects at <strong><a href="https://idx.edu.vn/" target="_blank" rel="noopener noreferrer"> the Digital Transformation Institute (iDX)</a>. </strong>. 
        I love programming, am always creative in the IT field with ideas for applying technology and algorithms to practically solve social problems, with experience working with <strong><a href="https://www.hippotnc.com/" target="_blank" rel="noopener noreferrer">Hippo T&C Inc. (South Korea)</a></strong> in the <strong>Bluekare-D</strong> project.</p>
      </section>
      <section>
        <h2>Working Experiences</h2>
        <p>
          <strong>Software engineer trainee</strong><br />
          <span className="company-name">INSTITUTE OF DIGITAL TRANSFORMATION (iDX)</span><br />
          <span className="experience-date">Jun. 2023 – Now</span>
        </p>
        <p>
          <strong>Backend developer - intern</strong><br />
          <span className="company-name">HIPPO T&C INC. COMPANY (S. KOREA)</span><br />
          <span className="experience-date">July. 2023 – Now</span>
        </p>
      </section>
      <section>
        <h2>Education</h2>
        <p>
            Software engineer at University of Industry and Trade, Ho Chi Minh City, Vietnam (HUIT)<br />
          <span className="experience-date">From 8/2021 – Now</span><br />
        </p>
        <p>
          Attend high school at Quang Trung High School (Tay Ninh province)<br />
          <span className="experience-date">From 8/2019 – 6/2021</span>
        </p>
      </section>
      <section>
        <h2>Professional Skills</h2>
        <p>
          Programming Languages: <span class="professional-skills">C/C++, C#, Java Script, Python, Java.</span><br />
          Frameworks, Libraries: <span class="professional-skills">Node.JS/Express; .Net framework (ASP.NET, Winform), Unity engine (2D game), Spring, Android, Docker.<br /></span>
          Databases: <span class="professional-skills"> MySQL, SQL Server, PostgreSQL, Redis.<br /></span>
          Cloud: <span class="professional-skills">AWS S3 (Lambda, ElastiCache).<br /></span>
          Git:<span class="professional-skills"> Github.</span>
        </p>
      </section>
      <section>
        <h2>Achievemen</h2>
        <p>Some award: <br />Consolation prize - Emergent products and topics in information technology field 2023.
        <br />Consolation prize - Looking for IT startup ideas 2024.</p>
      </section>
    </div>
  );
}

export default MainContent;