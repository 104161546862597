import React from 'react';
import '../public/Styles/Header.scss';

const Header = () => {
  const handleContactClick = () => {
    document.getElementById('contact-footer').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <header className="header">
      <div className="left">
        <img src="/Images/avatar.jpg" alt="Profile" className="profile-pic" />
        <div className="info">
          <h1>Trương Đặng Minh Tân (Hades Zhang)</h1>
          <p>Software Engineer</p>
        </div>
      </div>
      <div className="right">
        <a href="https://github.com/hadeszhanggg" target="_blank" rel="noopener noreferrer" className="nav-link">Github</a>
        <a href="/MyCV.pdf" target="_blank" className="nav-link">My CV</a>
        <button onClick={handleContactClick} className="contact-button">Contact</button>
      </div>
    </header>
  );
};

export default Header;
